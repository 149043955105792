import React from 'react';
import styled, { css } from 'styled-components';
import Countdown from '../atoms/Countdown';

import header_bg from '../../assets/images/curriculum_bg.png';
import CIELO from '../../assets/images/logo-cielo-light.png';
import SATI from '../../assets/images/sati_logo_h.png';
import ARROW from '../../assets/images/red_arrow_down.png';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* background-color: #f4f4f4; */
  font-family: 'Arial', sans-serif;
  padding: 20px;
  box-sizing: border-box;
  z-index: 1;
  overflow: scroll;
`;

const Heading = styled.h1`
  font-size: 36px;
  color: #fff;
  text-align: center;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 28px;
  }

  @media (max-width: 480px) {
    font-size: 22px;
  }
`;

const Content = styled.p`
  font-size: 18px;
  color: #fff;
  text-align: center;
  max-width: 620px;
  margin: 20px 0;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const Img = styled.img`
  width: 200px;
  height: 100px;
  object-fit: contain;
  overflow: hidden;
  ${(p) => p.theme.max('md')`
    object-fit: contain;
    width: 150px;
    `}
`;

const Img2 = styled.img`
  width: 100px;
  height: 100px;
  overflow: hidden;
  transform: rotate(270deg);
  ${(p) => p.theme.max('md')`
    object-fit: contain;
    width: 80%;
    height: 100%;
    height: 70px`}
`;

const Img3 = styled.img`
  width: 200px;
  height: 40px;
  overflow: hidden;
  ${(p) => p.theme.max('md')`
    object-fit: contain;
    width: 150px;
    `}
`;

const BgImage = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-image: url(${(p) => p.source});
  background-size: cover;
  background-position: center;
  opacity: ${(p) => p.opacity};
  transition: opacity ${(p) => (p.transition ? 1 : 0)}s ease;
  transform: translate3d(0, 0, 0);
`;

const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  height: 200px;
  ${(p) => p.theme.max('md')`
  gap: 10px;
  `}
`;

const Button = styled.button`
  position: relative;
  background: white;
  border-radius: 100px;
  padding: 25px 33px 25px 46px;
  width: 300px;
  border: 0;
  background: transparent;
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  font-family: TT Norms Pro, sans-serif;
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
  box-shadow: 5px 5px 11px rgba(0, 0, 0, 0.23);
  ${(p) =>
    p.disabled &&
    css`
      background: rgba(255, 255, 255, 0.05);
      color: rgba(255, 255, 255, 0.2);
    `}
  ${(p) => p.theme.max('md')`
    display: ${(p) =>
      (p.isIos && !p.isApple) || (!p.isIos && p.isApple)
        ? p.isPayment
          ? 'inline'
          : 'none'
        : 'inline'};
  `}
  &:hover {
    background: white;
    color: black;
    ${(p) =>
      p.disabled &&
      css`
        background: rgba(255, 255, 255, 0.05);
        color: rgba(255, 255, 255, 0.2);
      `}
    ${(p) =>
      p.isApple &&
      css`
        > div {
          filter: invert(0%);
        }
      `}
  }
  ${(p) =>
    p.small &&
    css`
      font-size: 16px;
      width: 235px;
      padding: 8px 24px 8px 38px;
      > div {
        width: 18px;
        height: 18px;
      }
    `}
  ${(p) => p.theme.max('md')`
    background: white;
    color: black;
    ${(p) =>
      p.disabled &&
      css`
        background: rgba(255, 255, 255, 0.05);
        color: rgba(255, 255, 255, 0.2);
      `}
    ${(p) =>
      p.isApple &&
      css`
        > div {
          filter: invert(0%);
        }
      `}
  `}
`;

const ConstructionPage = () => {
  const redirect = () => {
    window.location.href = 'https://cielomeditation.ru/';
  };

  const { timeLeft } = Countdown({ seconds: 60, onComplete: redirect });
  return (
    <>
      <BgImage source={header_bg} opacity={1} />

      <PageContainer>
        <ImgContainer>
          <Img src={SATI} />
          <Img2 src={ARROW} />
          <Img3 src={CIELO} />
        </ImgContainer>
        <Heading>Мы переехали!</Heading>
        <Content>
          Рады сообщить, что теперь мы — CIELO!
          <br />
          Это изменение — новый виток в развитии осознанности, отражающий наше
          стремление объединить расширенный функционал и создать более полный
          путь к осознанной жизни.
          <br />
          <br />
          {/* <Heading>Flero теперь F Date&eat</Heading>
    А название теперь говорит само за себя!
    <br />
    <br /> */}
          В CIELO вас ждёт всё тот же «Путь осознанности SATI», а также новый,
          структурированный авторский курс «Путь осознанного сна SATI»,
          дыхательные практики для улучшения самочувствия и интегрированные
          визуальные техники для глубокого расслабления и фокусировки.
          <br />
          <br />
          Через {timeLeft} секунд вы окажетесь на нашем новом сайте, где сможете
          скачать обновлённую версию приложения.
          <br />
          <br />
          <Heading>Или переходите по ссылке</Heading>
          <br />
          <Button
            //     isApple={isApple}
            // isIos={isIos}
            // isPayment={isPayment}
            // onClick={disabled ? null : isScrollButton ? scrollToBottom : goTo}
            // small={small}
            // disabled={disabled}
            onClick={redirect}>
            Перейти на новый сайт
          </Button>
        </Content>
      </PageContainer>
    </>
  );
};

export default ConstructionPage;
